<template>
	<el-dialog ref="form" :model="form" :visible.sync="dialog" center class="transfer-dialog">
		<el-form ref="form" :model="form" :rules="rules">
			<el-form-item label="请选择转单对象">
				<el-checkbox v-model="form.transferType" :true-label="1" :false-label="1" class="send-type-checkbox">
					以通讯组为单位选择 </el-checkbox>
				<el-checkbox v-model="form.transferType" :true-label="2" :false-label="2" class="send-type-checkbox">
					以签约类型为单位选择 </el-checkbox>
				<el-checkbox v-model="form.transferType" :true-label="4" :false-label="4" class="send-type-checkbox">
					以经销商为单位选择 </el-checkbox>
			</el-form-item>

			<el-form-item label="">
				<el-cascader v-if="form.transferType === 1" :options="departmentOptions" :props="departmentProps"
					v-model="sendTargetDepartment" clearable placeholder="请选择通讯组" class="full-width"></el-cascader>
				<el-select v-else-if="form.transferType === 2" v-model="sendTargetSign" multiple filterable clearable
					placeholder="请选择签约类型" class="full-width">
					<el-option v-for="option in contractOptions" :key="option.id" :label="option.name"
						:value="option.id"></el-option>
				</el-select>
				<el-select v-else-if="form.transferType === 4" v-model="sendTargetAgent" multiple filterable
					reserve-keyword remote clearable :remote-method="remoteDealerMethod" placeholder="请选择经销商" class="full-width">
					<el-option v-for="option in agentOptions" :key="option.id" :label="option.name"
						:value="option.id"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="接收/抄送 用户">
				<el-select v-model="sendTargetUser" placeholder="请选择用户"
					multiple filterable reserve-keyword remote clearable :remote-method="remoteUserMethod"
					popper-class="user-select" class="full-width">
					<el-option v-for="(option,i) in userOptions" :key="i" :label="option.fullName"
						:value="option.id"></el-option>
				</el-select>
			</el-form-item>
		</el-form>

		<template slot="footer">
			<el-button @click="dialog = false"> 取消 </el-button>
			<el-button type="primary" @click="transfer" :loading="transferring"> 确认 </el-button>
		</template>
	</el-dialog>
</template>

<script>
	import {
		debounce
	} from '@/utils/debounce'

	export default {
		name: 'TransferDialog',
		props: {
			subjectId: {
				type: String,
				required: true
			},
			visible: {
				type: Boolean,
				required: true
			}
		},
		watch: {
			dialog(val) {
				this.$emit('update:visible', val)
			},
			visible(val) {
				this.dialog = val
				if (val && !this.dataLoaded) {
					this.getDepartment()
					this.getDictData()
					this.getAllUserList()
					this.getDealerList()
					this.dataLoaded = true
				}
			},
			sendTargetList(val) {
				this.form.sendTargetList = val
			}
		},
		computed: {
			transferType() {
				return this.form.transferType
			},
			// 所有的部门列表
			allDepartments() {
				const getChildrens = (department) => {
					const children = []
					if (department.children && department.children.length > 0) {
						for (const child of department.children) {
							children.push(child)
							const c = getChildrens(child)
							children.push.apply(children, c)
						}
					}
					return children
				}

				const departments = []
				for (const department of this.departmentOptions) {
					departments.push(department)
					const children = getChildrens(department)
					departments.push.apply(departments, children)
				}

				return departments
			},
			sendTargetList() {
				const targets = []
				if (this.form.transferType === 1) {
					// 通信组
					for (const t of this.sendTargetDepartment) {
						for (const c of this.allDepartments) {
							if (t === c.id) {
								const obj = {
									sendType: 1,
									sendName: c.label,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				} else if (this.form.transferType === 2) {
					// 签约类型
					for (const t of this.sendTargetSign) {
						for (const c of this.contractOptions) {
							if (t === c.id) {
								const obj = {
									sendType: 2,
									sendName: c.name,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				} else if (this.form.transferType === 4) {
					// 经销商
					for (const t of this.sendTargetAgent) {
						for (const c of this.agentOptions) {
							if (t === c.id) {
								const obj = {
									sendType: 4,
									sendName: c.name,
									sendVal: t
								}
								targets.push(obj)
								break
							}
						}
					}
				}

				// 用户
				if (this.sendTargetUser && this.sendTargetUser.length > 0) {
					for (const userId of this.sendTargetUser) {
						for (const u of this.userOptions) {
							if (userId === u.id) {
								const obj = {
									sendType: 3,
									sendName: u.name,
									sendVal: userId
								}
								targets.push(obj)
							}
						}
					}
				}

				return targets
			}
		},
		created() {
			// this.getDepartment()
			// this.getAgent()
			// this.getDictData()
			// this.getAllUsers()
		},
		data() {
			return {
				dialog: false,
				// 接口数据是否已加载
				dataLoaded: false,
				form: {
					transferType: 1, // 发送对象选项 1:以通讯组为单位  2:以签约类型为单位  3:用户
					sendTargetList: []
				},
				// 正在转单
				transferring: false,
				// 签约类型发送对象
				sendTargetSign: [],
				// 分公司发送对象
				sendTargetDepartment: [],
				// 经销商发送对象
				sendTargetAgent: [],
				// 用户发送对象
				sendTargetUser: [],
				// 通讯组数据
				departmentOptions: [],
				// 签约类型数据
				contractOptions: [],
				// 经销商数据
				agentOptions: [],
				// 用户数据
				userOptions: [],
				userOptionsCopy: [],
				// 搜索用用户名称
				searchUserName: '',
				// 组织架构级联选择器选项
				departmentProps: {
					multiple: true,
					emitPath: false,
					// checkStrictly: true,
					value: 'id',
					label: 'label',
					children: 'children'
				},
				rules: {
					transferType: [{
						required: true,
						message: '请选择接收对象',
						trigger: 'change'
					}]
				}
			}
		},
		methods: {
			// 获取通讯组数据
			getDepartment() {
				let that = this
				that.$request.get(
					"sysDepartSelector",
					false, {},
					function (r) {
						if (r.code == "0") {
							if (r.data && r.data.tree) {
								that.departmentOptions = r.data.tree
							}
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},

			// 签约类型数据
			getDictData() {
				let that = this
				that.$request.post(
					"getListByCodes",
					true, {
						codes: 'contractType'
					},
					function (r) {
						if (r.code == "0") {
							that.contractOptions = r.data.contractType
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 经销商下拉优化
			remoteDealerMethod(query) {
				if (query == '') {
					return
				}
				this.getDealerList(this.sendTargetAgent.join(','),query);
			},
			// 获取经销商数据
			getDealerList(dealerId,searchKey) {
				let that = this;
				that.$request.post(
					"getDealerLists",
					false,
					{
						searchKey: searchKey,
						dealerId: dealerId
					},
					function (r) {
						if (r.code == "0") {
							that.agentOptions = r.data;
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			},
			// 用户下拉优化
			remoteUserMethod(query) {
				if (query == '') {
					return
				}
				this.getAllUserList(this.sendTargetUser.join(','),query);
			},
			// 获取所有用户数据
			getAllUserList(dealerId,searchKey) {
				let that = this
				that.$request.get(
					"sysUserAll",
					false, {
						searchKey: searchKey,
						dealerId: dealerId
					},
					function (r) {
						if (r.code == "0") {
							that.userOptions = r.data;
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 转单
			transfer: debounce(function () {
				if (!this.dialog) return

				this.$refs['form'].validate((valid) => {
					if (valid) {
						if (!this.form.sendTargetList || this.form.sendTargetList.length === 0) {
							this.$message.error('请选择接收对象或用户')
							return false
						}

						this.transferring = true
						const data = Object.assign({}, this.form, {
							id: this.subjectId
						})
						let that = this
						that.$request.post(
							"dataSubjectTransfer",
							false,
							data,
							function (r) {
								if (r.code == "0") {
									that.dialog = false
									that.$message.success('转单成功')
									that.$emit('success')
									that.$refs['form'].resetFields()
									that.sendTargetDepartment = []
									that.sendTargetSign = []
									that.sendTargetAgent = []
									that.sendTargetUser = []
								} else {
									that.$message.error(r.msg)
								}
								that.transferring = false
								that.$emit('onTransferSuccess')
							}
						)
					} else {
						return false
					}
				})
			}, 1000)
		}
	}

</script>

<style lang="scss">
	.user-select {
		max-width: 300px;
	}

</style>
